<template>
  <v-container grid-list-xl fluid>
    <v-row no-gutters>
      <v-col cols="12" class="pt-0">
        <v-card class="elevation-0">
          <v-card-title class="pa-4">
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <search-field @search="search" :title="'Search'"></search-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="3"
                lg="3"
                xl="3"
                offset-md="5"
                offset-lg="5"
                offset-xl="5"
                order="first"
                order-sm="first"
                order-md="last"
                order-lg="last"
                order-xl="last"
                :class="{ 'pb-4': $vuetify.breakpoint.smAndDown }"
              >
                <div class="float-right">
                  <fab-button
                    v-if="$moduleAccess('add')"
                    @click="openAddDialog()"
                    dark
                    top
                    xsmall
                    :icon="'mdi-plus'"
                    :tooltipSpan="'Add'"
                  ></fab-button
                  >&nbsp;
                  <fab-button
                    @click="getData()"
                    dark
                    top
                    xsmall
                    :icon="'mdi-refresh'"
                    :tooltipSpan="'Refresh'"
                  ></fab-button>
                </div>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
        <v-divider></v-divider>
        <v-card class="elevation-0">
          <v-container fluid>
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-row>
                  <v-col cols="12" class="py-2">
                    <!-- <v-btn text @click="openFilterExpansion">
                      <v-icon>{{ openFilter ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                      <span>&nbsp;&nbsp;Filter {{ title }}</span>
                    </v-btn>-->
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
                offset-md="2"
                lg="6"
                offset-lg="2"
                xl="6"
                offset-xl="2"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="9"
                    offset-md="3"
                    lg="9"
                    offset-lg="3"
                    xl="9"
                    offset-xl="3"
                    class="py-2"
                  >
                    <div
                      class="float-right d-none d-sm-flex d-sm-none d-md-flex"
                    >
                      <p
                        class="text-xs-right grey--text caption my-2 mx-2"
                        style="font-size: 14px; margin-top: 14px; display: inline-block;"
                      >
                        Row per Page:
                      </p>
                      <v-select
                        style="font-size: 14px; max-width: 60px; display: inline-block; height: 34px;"
                        :items="page"
                        class="grey--text mx-2"
                        id="selected"
                        v-model="pageLimit"
                        @input="limitClick"
                        dense
                        height="20px"
                        :disabled="this.items.length < 10 ? true : false"
                      ></v-select>
                      <p
                        class="text-xs-center grey--text caption my-2 mx-1"
                        style="font-size: 14px;display: inline-block;"
                      >
                        {{ itemFrom + " -" + itemTo + " of " }} {{ totalItems }}
                      </p>

                      <v-btn
                        style="display: inline-block; height: 34px;"
                        fab
                        light
                        small
                        text
                        @click="backClick"
                        :disabled="disabledBack"
                      >
                        <v-icon dark>mdi-chevron-left</v-icon>
                      </v-btn>
                      <v-btn
                        style="display: inline-block; height: 34px;"
                        fab
                        light
                        small
                        text
                        @click="nextClick"
                        :disabled="disabledNext"
                      >
                        <v-icon dark>mdi-chevron-right</v-icon>
                      </v-btn>
                    </div>
                    <v-row
                      no-gutters
                      class="d-flex d-sm-none d-none d-sm-flex d-md-none"
                    >
                      <v-col cols="12" sm="12">
                        <p
                          class="text-xs-right grey--text caption my-2 mx-2"
                          style="font-size: 14px; margin-top: 14px; display: inline-block;"
                        >
                          Row per Page:
                        </p>
                        <v-select
                          style="font-size: 14px; max-width: 60px; display: inline-block; height: 34px;"
                          :items="page"
                          class="grey--text mx-2"
                          id="selected"
                          v-model="pageLimit"
                          @input="limitClick"
                          dense
                          height="20px"
                          :disabled="this.items.length < 10 ? true : false"
                        ></v-select>
                      </v-col>
                      <v-col cols="2" sm="2">
                        <div class="float-left float-sm-left">
                          <v-btn
                            style="display: inline-block; height: 34px;"
                            fab
                            light
                            small
                            text
                            @click="backClick"
                            :disabled="disabledBack"
                          >
                            <v-icon dark>mdi-chevron-left</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                      <v-col cols="8" sm="8" style="text-align: center;">
                        <p
                          class="text-xs-center grey--text caption my-2 mx-1"
                          style="font-size: 14px; display: inline-block;"
                        >
                          {{ itemFrom + " -" + itemTo + " of " }}
                          {{ totalItems }}
                        </p>
                      </v-col>
                      <v-col cols="2" sm="2">
                        <div class="float-right float-sm-right">
                          <v-btn
                            style="display: inline-block; height: 34px;"
                            fab
                            light
                            small
                            text
                            @click="nextClick"
                            :disabled="disabledNext"
                          >
                            <v-icon dark>mdi-chevron-right</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-divider></v-divider>
        <v-data-table
          :headers="headers"
          :items="items"
          class="elevation-1"
          disable-pagination
          hide-default-footer
          :server-items-length="items.length"
        >
          <template v-if="items.length === 0" v-slot:no-data>
            <v-container>
              <v-row>
                <v-col md="6" offset-md="3">
                  <span class="title font-weight-bold">NO DATA AVAILABLE.</span>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-else v-slot:body="{ items }">
            <tbody>
              <tr v-for="(item, index) in items" :key="index">
                <td class="text-center">
                  {{ index + queryParams.offset + 1 }}
                </td>
                <td class="text-no-wrap">
                  {{ item.issued_company_id ? item.issued_company_id : "--" }}
                </td>
                <td class="text-uppercase text-no-wrap">{{ item.name }}</td>
                <td class="text-uppercase text-no-wrap">{{ item.email }}</td>
                <td class="text-uppercase text-no-wrap">
                  {{ item.contact_no }}
                </td>
                <td class="text-uppercase text-no-wrap">
                  {{ item.role ? item.role.name : "--" }}
                </td>
                <td class="text-uppercase text-no-wrap">
                  {{ item.company_role ? item.company_role.name : "--" }}
                </td>
                <td class="text-uppercase text-no-wrap">
                  {{
                    item.sub_company_role ? item.sub_company_role.name : "--"
                  }}
                </td>
                <td class="text-center text-no-wrap">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="blue"
                        icon
                        @click="openViewDialog(item)"
                        v-on="on"
                      >
                        <v-icon>mdi-magnify</v-icon>
                      </v-btn>
                    </template>
                    <span>View</span>
                  </v-tooltip>
                  <v-tooltip v-if="$moduleAccess('edit')" top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="teal"
                        icon
                        @click="openEditDialog(item)"
                        v-on="on"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip
                    v-if="$moduleAccess('reset password') && item.user_access"
                    top
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="blue darken-3"
                        icon
                        @click="openConfirmResetPasswordDialog(item)"
                        v-on="on"
                      >
                        <v-icon>mdi-lock-reset</v-icon>
                      </v-btn>
                    </template>
                    <span>Reset Password</span>
                  </v-tooltip>
                  <v-tooltip v-if="$moduleAccess('delete') && !item.super" top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="red"
                        icon
                        @click="openDeleteDialog(item)"
                        v-on="on"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!---- Here is the Add, View, Edit Employee Dialog ----->

    <full-width-dialog
      :dialog="fullWidthDialog"
      :title="dialogType + ' ' + title"
      :color="
        dialogType === 'Add'
          ? 'amber'
          : dialogType === 'View'
          ? 'blue'
          : dialogType === 'Edit'
          ? 'teal'
          : ''
      "
      :dialogLoading="fullWidthDialogLoading"
      :dialogLoadingColor="
        dialogType === 'Add'
          ? 'amber'
          : dialogType === 'View'
          ? 'blue'
          : dialogType === 'Edit'
          ? 'teal'
          : ''
      "
      :saveButton="saveButton"
      dark
      @saveDialog="
        dialogType === 'Add'
          ? validateAddDialog()
          : dialogType === 'Edit'
          ? validateEditDialog()
          : ''
      "
      @closeDialog="
        dialogType === 'Add'
          ? closeAddDialog()
          : dialogType === 'View'
          ? closeViewDialog()
          : dialogType === 'Edit'
          ? closeEditDialog()
          : ''
      "
    >
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="12" md="1" lg="1" xl="1"></v-col>
          <v-col cols="12" sm="12" md="10" lg="10" xl="10">
            <details-container
              :data="data"
              :userData="userData"
              :roles="roles"
              :companyRoles="companyRoles"
              :subCompanyRoles="subCompanyRoles"
              :readonly="readonly"
              :dialogType="dialogType"
              showRole
              showCompanyRole
              showSubCompanyRole
              ref="detailsContainer"
              @openSaveDialog="
                dialogType === 'Add'
                  ? openSaveAddDialog()
                  : dialogType === 'Edit'
                  ? openSaveEditDialog()
                  : ''
              "
            ></details-container>
          </v-col>
          <v-col cols="12" sm="12" md="1" lg="1" xl="1"></v-col>
        </v-row>
      </v-container>
    </full-width-dialog>

    <!---- Here is the Confirmation of Adding Employee/s ---->

    <mini-dialog
      :dialog="saveAddDialog"
      :max-width="'400'"
      :confirmSave="true"
      :loading="confirmLoading"
      @closeDialog="closeSaveAddDialog()"
      @confirmDialog="addEmployee()"
    ></mini-dialog>

    <!---- Here is the Confirmation of Editing Employee/s ---->

    <mini-dialog
      :dialog="saveEditDialog"
      :max-width="'400'"
      :confirmSave="true"
      :loading="confirmLoading"
      @closeDialog="closeSaveEditDialog()"
      @confirmDialog="editEmployee()"
    ></mini-dialog>

    <!---- Here is the Confirmation of Deleting Employee/s ---->

    <mini-dialog
      :dialog="deleteDialog"
      :max-width="'400'"
      :confirmSave="true"
      :loading="confirmLoading"
      :cardTitle="'Delete'"
      :cardText="'Are you sure you want to delete this?'"
      @closeDialog="closeDeleteDialog()"
      @confirmDialog="deleteEmployee()"
    ></mini-dialog>

    <!---- Here is the Confirmation of Employee/s Reset Password ---->

    <mini-dialog
      :dialog="resetPasswordDialog"
      :max-width="'400'"
      :confirmSave="true"
      :loading="confirmLoading"
      @closeDialog="closeConfirmResetPasswordDialog()"
      @confirmDialog="resetPassword()"
    ></mini-dialog>
  </v-container>
</template>

<script>
import Services from "@/services/Services";
import DetailsContainer from "@/components/details_container/EmployeeDetailsContainer";

export default {
  components: {
    DetailsContainer,
  },
  data: () => {
    return {
      title: "Employee",
      items: [],
      queryParams: {
        search: "",
        limit: 10,
        offset: 0,
      },
      headers: [
        {
          text: "#",
          sortable: false,
          align: "center",
        },
        { text: "Employee ID", sortable: false },
        { text: "Name", sortable: false },
        { text: "Email", sortable: false },
        { text: "Contact No", sortable: false },
        { text: "Role", sortable: false },
        { text: "Company Role", sortable: false },
        { text: "Sub Company Role", sortable: false },
        { text: "Action", sortable: false, align: "center" },
      ],
      data: {},
      userData: {},
      totalItems: 0,
      itemFrom: 0,
      itemTo: 0,
      disabledNext: false,
      disabledBack: true,
      disabledLimit: true,
      page: [
        { text: 10, value: 10 },
        { text: 20, value: 20 },
        { text: 30, value: 30 },
        { text: 40, value: 40 },
        { text: 50, value: 50 },
        { text: 60, value: 60 },
        { text: 70, value: 70 },
        { text: 80, value: 80 },
        { text: 90, value: 90 },
        { text: 100, value: 100 },
      ],
      fullWidthDialog: false,
      confirmLoading: false,
      fullWidthDialogLoading: false,
      dialogType: "",
      saveButton: false,
      readonly: false,
      roles: [],
      companyRoles: [],
      subCompanyRoles: [],
      saveAddDialog: false,
      saveEditDialog: false,
      deleteDialog: false,
      openFilter: false,
      resetPasswordDialog: false,
    };
  },
  created: function() {
    if (this.$store.state.token !== null) {
      this.getData();
    } else {
      this.$router.push({
        path: "/",
      });

      this.$store.dispatch("clearStore");
    }
  },
  computed: {
    pageLimit: {
      get() {
        return 10;
      },
      set(val) {
        this.queryParams.limit = val;
        this.queryParams.offset = 0;
      },
    },
  },
  methods: {
    async search(data) {
      this.queryParams.search = data;
      await this.getData();
    },
    async getData() {
      this.$store.dispatch("startLoading");

      this.queryParams.company_id = this.$store.state.company.id;

      try {
        let response = (await Services.getEmployees(this.queryParams)).data;

        this.items = response.data;

        // Assign Total number of data
        this.totalItems = response.count;

        // Assign Number started the data
        this.itemFrom = this.totalItems > 0 ? this.queryParams.offset + 1 : 0;

        // Assign Number end the data
        this.itemTo = this.queryParams.offset + this.queryParams.limit;

        if (this.totalItems <= this.itemTo) {
          this.itemTo = this.totalItems;
        }

        // Conditions for NextPage Button
        if (this.itemTo === this.totalItems) {
          this.disabledNext = true;
        } else {
          this.disabledNext = false;
        }
        // Conditions for BackPage Button
        if (this.itemFrom > 1) {
          this.disabledBack = false;
        } else {
          this.disabledBack = true;
        }

        if (this.totalItems <= this.queryParams.limit) {
          this.disabledLimit = true;
        } else {
          this.disabledLimit = false;
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network or server connection",
          });
        }
      }

      this.$store.dispatch("endLoading");
    },
    async openFilterExpansion() {
      this.openFilter = await !this.openFilter;
    },
    nextClick() {
      this.queryParams.offset += this.queryParams.limit;
      this.getData();
    },
    backClick() {
      this.queryParams.offset -= this.queryParams.limit;
      this.getData();
    },
    async limitClick() {
      this.queryParams.offset = 0;
      this.getData();
    },
    validateForm() {
      this.$refs.detailsContainer.validateForm();
    },
    async openAddDialog() {
      this.fullWidthDialog = true;
      this.fullWidthDialogLoading = true;
      this.dialogType = "Add";
      this.saveButton = true;
      this.readonly = false;

      this.data.user_access = false;
      this.data.contact_no = "";
      this.data.email = "";
      this.data.fax_no = "";
      this.data.first_name = "";
      this.data.issued_company_id = "";
      this.data.last_name = "";
      this.data.middle_name = "";
      this.data.position = "";
      this.userData.username = "";
      this.data.role_id = null;
      this.data.company_role_id = null;
      this.data.sub_company_role_id = null;
      // this.data.is_agent = false;

      this.roles = await this.setRoles();

      this.companyRoles = await this.setCompanyRoles();

      this.subCompanyRoles = await this.setSubCompanyRoles();

      setTimeout(() => {
        this.fullWidthDialogLoading = false;
      }, 600);
    },
    closeAddDialog() {
      this.fullWidthDialog = false;
    },
    validateAddDialog() {
      this.$refs.detailsContainer.validateForm();
    },
    openSaveAddDialog() {
      this.saveAddDialog = true;
    },
    closeSaveAddDialog() {
      this.saveAddDialog = false;
    },
    async addEmployee() {
      this.confirmLoading = true;

      const data = this.$refs.detailsContainer.computedData;

      const body = {
        issued_company_id: data.issued_company_id,
        first_name: data.first_name,
        last_name: data.last_name,
        middle_name: data.middle_name,
        position: data.position,
        contact_no: data.contact_no,
        fax_no: data.fax_no,
        email: data.email,
        tracking_role_id: data.role_id,
        company_role_id: data.company_role_id,
        sub_company_role_id: data.sub_company_role_id,
        access: data.access,
        username: data.username,
        // is_agent: data.is_agent,
      };

      try {
        const response = await Services.addEmployee(body);

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          this.closeAllDialog();
          await this.getData();
          this.$infoBar({
            type: "success",
            text: this.title + " has been added!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }
      this.confirmLoading = await false;
    },
    async openViewDialog(item) {
      this.fullWidthDialog = true;
      this.fullWidthDialogLoading = true;
      this.dialogType = "View";
      this.saveButton = false;
      this.readonly = true;

      this.roles = await this.setRoles();

      this.companyRoles = await this.setCompanyRoles();

      this.subCompanyRoles = await this.setSubCompanyRoles();

      const returnedEmployee = await this.setEmployee(item.id);

      this.data.user_access =
        returnedEmployee.user_access === 1 ||
        returnedEmployee.user_access === 201
          ? true
          : false;
      this.data.contact_no = returnedEmployee.contact_no
        ? returnedEmployee.contact_no
        : "";
      this.data.email = returnedEmployee.email ? returnedEmployee.email : "";
      this.data.fax_no = returnedEmployee.fax_no ? returnedEmployee.fax_no : "";
      this.data.first_name = returnedEmployee.first_name
        ? returnedEmployee.first_name.toUpperCase()
        : "";
      this.data.issued_company_id = returnedEmployee.issued_company_id
        ? returnedEmployee.issued_company_id
        : "";
      this.data.last_name = returnedEmployee.last_name
        ? returnedEmployee.last_name.toUpperCase()
        : "";
      this.data.middle_name = returnedEmployee.middle_name
        ? returnedEmployee.middle_name.toUpperCase()
        : "";
      this.data.position = returnedEmployee.position
        ? returnedEmployee.position.toUpperCase()
        : "";
      this.userData.username = returnedEmployee.user
        ? returnedEmployee.user.username
        : null;
      this.data.role_id = returnedEmployee.tracking_role_id;
      this.data.company_role_id = returnedEmployee.company_role_id;
      this.data.sub_company_role_id = returnedEmployee.sub_company_role_id;
      this.data.created_at = returnedEmployee.created_at;
      this.data.updated_at = returnedEmployee.updated_at;
      this.data.is_agent = returnedEmployee.is_agent;

      setTimeout(() => {
        this.fullWidthDialogLoading = false;
      }, 600);
    },
    closeViewDialog() {
      this.fullWidthDialog = false;
    },
    async openEditDialog(item) {
      this.fullWidthDialog = true;
      this.fullWidthDialogLoading = true;
      this.dialogType = "Edit";
      this.saveButton = true;
      this.readonly = false;

      this.roles = await this.setRoles();

      this.companyRoles = await this.setCompanyRoles();

      this.subCompanyRoles = await this.setSubCompanyRoles();

      const returnedEmployee = await this.setEmployee(item.id);

      this.data.id = returnedEmployee.id;
      this.data.user_access =
        returnedEmployee.user_access === 1 ||
        returnedEmployee.user_access === 201
          ? true
          : false;
      this.data.contact_no = returnedEmployee.contact_no
        ? returnedEmployee.contact_no
        : "";
      this.data.email = returnedEmployee.email ? returnedEmployee.email : "";
      this.data.fax_no = returnedEmployee.fax_no ? returnedEmployee.fax_no : "";
      this.data.first_name = returnedEmployee.first_name
        ? returnedEmployee.first_name.toUpperCase()
        : "";
      this.data.issued_company_id = returnedEmployee.issued_company_id
        ? returnedEmployee.issued_company_id
        : "";
      this.data.last_name = returnedEmployee.last_name
        ? returnedEmployee.last_name.toUpperCase()
        : "";
      this.data.middle_name = returnedEmployee.middle_name
        ? returnedEmployee.middle_name.toUpperCase()
        : "";
      this.data.position = returnedEmployee.position
        ? returnedEmployee.position.toUpperCase()
        : "";
      this.userData.username = returnedEmployee.user
        ? returnedEmployee.user.username
        : null;
      this.data.role_id = returnedEmployee.tracking_role_id;
      this.data.company_role_id = returnedEmployee.company_role_id;
      this.data.sub_company_role_id = returnedEmployee.sub_company_role_id;
      this.data.created_at = returnedEmployee.created_at;
      this.data.updated_at = returnedEmployee.updated_at;
      this.data.is_agent = returnedEmployee.is_agent;

      setTimeout(() => {
        this.fullWidthDialogLoading = false;
      }, 600);
    },
    closeEditDialog() {
      this.fullWidthDialog = false;
    },
    validateEditDialog() {
      this.$refs.detailsContainer.validateForm();
    },
    openSaveEditDialog() {
      this.saveEditDialog = true;
    },
    closeSaveEditDialog() {
      this.saveEditDialog = false;
    },
    async editEmployee() {
      this.confirmLoading = true;

      const data = this.$refs.detailsContainer.computedData;

      const body = {
        issued_company_id: data.issued_company_id,
        first_name: data.first_name,
        last_name: data.last_name,
        middle_name: data.middle_name,
        position: data.position,
        contact_no: data.contact_no,
        fax_no: data.fax_no,
        email: data.email,
        tracking_role_id: data.role_id,
        company_role_id: data.company_role_id,
        sub_company_role_id: data.sub_company_role_id,
        access: data.access,
        username: data.username,
        // is_agent: data.is_agent,
      };

      try {
        const response = await Services.editEmployee(this.data.id, body);

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          this.closeAllDialog();
          await this.getData();
          this.$infoBar({
            type: "success",
            text: this.title + " has been edited!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }

      this.confirmLoading = await false;
    },
    openDeleteDialog(item) {
      this.deleteDialog = true;
      this.data.id = item.id;
    },
    closeDeleteDialog() {
      this.deleteDialog = false;
    },
    async deleteEmployee() {
      this.confirmLoading = true;

      try {
        const response = await Services.deleteEmployee(this.data.id);

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          this.closeAllDialog();
          await this.getData();
          this.$infoBar({
            type: "success",
            text: this.title + " has been deleted!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }
      this.confirmLoading = await false;
    },
    openConfirmResetPasswordDialog(item) {
      this.resetPasswordDialog = true;
      this.data.id = item.id;
    },
    closeConfirmResetPasswordDialog() {
      this.resetPasswordDialog = false;
    },
    async resetPassword() {
      this.confirmLoading = true;

      try {
        const response = await Services.resetPassword(this.data.id);

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          this.closeAllDialog();
          await this.getData();
          this.$infoBar({
            type: "success",
            text: this.title + " password has been reset!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }
      this.confirmLoading = await false;
    },
    async setRoles() {
      let data;

      try {
        const response = await Services.getRoles({});

        data = response.data.data;
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network or server connection",
          });
        }
      }

      const finalData = await Promise.all(
        data.map(async (item) => {
          return {
            text: item.name.toUpperCase(),
            value: item.id,
          };
        })
      );

      return finalData;
    },
    async setCompanyRoles() {
      let data;

      try {
        const response = await Services.getCompanyRoles({});

        data = response.data.data;
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network or server connection",
          });
        }
      }

      const finalData = await Promise.all(
        data.map(async (item) => {
          return {
            text: item.name.toUpperCase(),
            value: item.id,
          };
        })
      );

      return finalData;
    },
    async setSubCompanyRoles() {
      let data;

      try {
        const response = await Services.getSubCompanyRoles({});

        data = response.data.data;
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network or server connection",
          });
        }
      }

      const finalData = await Promise.all(
        data.map(async (item) => {
          return {
            text: item.name.toUpperCase(),
            value: item.id,
          };
        })
      );

      return finalData;
    },
    async setEmployee(id) {
      const response = await Services.getEmployee(id);

      return response.data;
    },
    closeAllDialog() {
      this.closeAddDialog();
      this.closeSaveAddDialog();
      this.closeViewDialog();
      this.closeEditDialog();
      this.closeSaveEditDialog();
      this.closeDeleteDialog();
      this.closeConfirmResetPasswordDialog();
    },
    titleCase(str) {
      let splitStr = str.toLowerCase().split(" ");
      for (let i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    },
  },
};
</script>

<style></style>
