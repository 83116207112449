<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title style="background-color: #EEEEEE;"
            >Employee Details</v-card-title
          >
          <v-divider></v-divider>
          <v-card-text>
            <v-form ref="formEmployee">
              <v-row>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                  <v-text-field
                    v-model="data.first_name"
                    label="First Name"
                    required
                    :rules="[rules.required]"
                    :readonly="readonly"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                  <v-text-field
                    v-model="data.middle_name"
                    label="Middle Name"
                    :readonly="readonly"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                  <v-text-field
                    v-model="data.last_name"
                    label="Last Name"
                    required
                    :rules="[rules.required]"
                    :readonly="readonly"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                  <v-text-field
                    v-model="data.email"
                    label="Email"
                    required
                    :rules="[rules.required, rules.email]"
                    :readonly="readonly"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                  <v-text-field
                    v-model="data.position"
                    label="Position"
                    required
                    :rules="[rules.required]"
                    :readonly="readonly"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                  <v-text-field
                    v-model="data.issued_company_id"
                    label="Issued Company ID"
                    required                    
                    :readonly="readonly"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                  <v-text-field
                    v-model="data.contact_no"
                    label="Contact No."
                    :readonly="readonly"
                  ></v-text-field>
                </v-col>
               
              </v-row>
              <v-row v-if="readonly">
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                  <span class="subtitle-1 font-weight-bold">Created At: </span
                  ><span class="subtitle-1">{{ data.created_at }}</span
                  ><br />
                  <br />
                  <span class="subtitle-1 font-weight-bold">Modified At: </span
                  ><span class="subtitle-1">{{ data.updated_at }}</span>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="data.user_access || !readonly">
      <v-col cols="12" sm="12" md="5" lg="5" xl="5">
        <v-card>
          <v-card-title style="background-color: #EEEEEE;"
            >Employee Access</v-card-title
          >
          <v-divider></v-divider>
          <v-card-text>
            <v-switch
              v-model="enableUserAccess"
              label="User Access"
              :readonly="readonly"
            ></v-switch>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-if="enableUserAccess" cols="12" sm="12" md="7" lg="7" xl="7">
        <v-card>
          <v-card-title style="background-color: #EEEEEE;"
            >User Details</v-card-title
          >
          <v-divider></v-divider>
          <v-card-text>
            <v-form ref="formUser">
              <v-row>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <v-text-field
                    v-model="username"
                    label="Username"
                    required
                    :rules="[rules.required]"
                    :readonly="readonly"
                  ></v-text-field>
                </v-col>
                <v-col
                  v-if="showRole"
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                >
                  <v-select
                    v-model="data.role_id"
                    label="Main Role"
                    required
                    :rules="[rules.required]"
                    :readonly="readonly"
                    :items="roles"
                  ></v-select>
                </v-col>
                <v-col
                  v-if="showCompanyRole"
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                >
                  <v-select
                    v-model="data.company_role_id"
                    label="Company Role"
                    required
                    :rules="[rules.required]"
                    :readonly="readonly"
                    :items="companyRoles"
                  ></v-select>
                </v-col>
                <v-col
                  v-if="showSubCompanyRole"
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                >
                  <v-select
                    v-model="data.sub_company_role_id"
                    label="Sub Company Role"
                    :readonly="readonly"
                    :items="subCompanyRoles"
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    userData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    showRole: {
      type: Boolean,
      default: false,
    },
    showCompanyRole: {
      type: Boolean,
      default: false,
    },
    showSubCompanyRole: {
      type: Boolean,
      default: false,
    },
    roles: {
      type: Array,
      default: () => {
        return [];
      },
    },
    companyRoles: {
      type: Array,
      default: () => {
        return [];
      },
    },
    subCompanyRoles: {
      type: Array,
      default: () => {
        return [];
      },
    },
    dialogType: {
      type: String,
      default: "",
    },
  },
  data: () => {
    return {
      rules: {
        required: (value) => !!value || "This field is required.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      enableUserAccess: null,
      isAgent: false,
      username: "",
    };
  },
  created: function() {
    this.enableUserAccess = this.data.user_access;
    this.username = this.userData.username;
  },
  computed: {
    computedData() {
      return {
        issued_company_id: this.data.issued_company_id,
        first_name: this.data.first_name,
        last_name: this.data.last_name,
        middle_name: this.data.middle_name,
        position: this.data.position,
        contact_no: this.data.contact_no,
        fax_no: this.data.fax_no,
        email: this.data.email,
        role_id: this.enableUserAccess ? this.data.role_id : null,
        company_role_id: this.enableUserAccess
          ? this.data.company_role_id
          : null,
        sub_company_role_id: this.enableUserAccess
          ? this.data.sub_company_role_id
          : null,
        company_id: this.$store.state.company
          ? this.$store.state.company.id
          : null,
        access: this.enableUserAccess,
        // username: this.enableUserAccess ? this.userData.username : null,
        username: this.enableUserAccess ? this.username : null,
        // password: this.enableUserAccess ? this.userData.password : null,
      };
    },
  },
  methods: {
    validateForm() {
      if (this.enableUserAccess) {
        if (
          this.$refs.formEmployee.validate() &&
          this.$refs.formUser.validate()
        ) {
          this.$emit("openSaveDialog");
        }
      } else {
        if (this.$refs.formEmployee.validate()) {
          this.$emit("openSaveDialog");
        }
      }
    },
  },
};
</script>

<style></style>
